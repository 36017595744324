// Copy text and update tooltips as a visible indicator
import { copyText } from './utilities';

export default function setupCopyable() {
    const delay = 2000;

    document.querySelectorAll('.copyable').forEach((copyable) => {
        if (copyable.dataset.initialized !== 'true') {
            copyable.dataset.initialized = 'true'; // Only initialize once...

            copyable.addEventListener('click', () => {
                let content = copyable.querySelector('.copyable__content');
                // It might be a sibling element, so check that next
                if (!content) {
                    content = copyable.parentElement.querySelector('.copyable__content');
                }

                let value = content.innerText;
                if (content.nodeName === 'INPUT') {
                    value = content.value;
                }
                copyText(value.trim());

                // Prefer to update the tooltip if present, otherwise update the text itself
                const text = copyable.querySelector('.copyable__text');
                const tooltipId = copyable.getAttribute('aria-describedby');
                if (tooltipId) {
                    const tooltip = document.getElementById(tooltipId);
                    if (!tooltip) return;

                    const originalText = copyable.dataset.tooltipContent;
                    if (tooltip.firstChild && tooltip.firstChild.nodeType === Node.TEXT_NODE) {
                        tooltip.firstChild.nodeValue = 'Copied';
                    }

                    // Reset after delay
                    setTimeout(() => {
                        if (tooltip.firstChild && tooltip.firstChild.nodeType === Node.TEXT_NODE) {
                            tooltip.firstChild.nodeValue = originalText;
                        }
                    }, delay);

                } else if (text) {
                    const textContent = text.innerText;
                    text.innerText = 'Copied';
                    setTimeout(() => {
                        text.innerText = textContent;
                    }, delay);
                }
            });
        }
    });
}
