//=================================================
// Utilities
//=================================================

// Check if two arrays share any elements
import { createRoot } from 'react-dom/client';
import * as qs from 'qs';
import { merge, each } from 'lodash';

import { getReducedMotion } from './user-preferences';
import { setupTooltips } from './tooltips';

const imagesLoaded = require('imagesloaded');

export function isAdmin() {
    return document.body.classList.contains('admin');
}

export function arrayMatches(arrayA, arrayB) {
    for (let i = 0; i < arrayA.length; i += 1) {
        for (let j = 0; j < arrayB.length; j += 1) {
            if (arrayB[j] === arrayA[i]) {
                return true;
            }
        }
    }
    return false;
}

// React helper for creating html markup
export function createMarkup(htmlString) {
    return { __html: htmlString };
}

// Helper for removing html or other bad characters for analytics
export function cleanText(htmlString) {
    if (htmlString) {
        return htmlString.replace(/<br>/g, ' ').replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
    }

    return '';
}

// Generate search query string from object
export function generateSearchQuery(obj) {
    let queryObj = qs.parse(window.location.search.slice(1));
    each(obj, (val, key) => {
        delete queryObj[key];
        // If not truthy, don't include. This is kind of dicey ~ colin
        if (!val) {
            return;
        }
        queryObj = merge({ [key]: val }, queryObj);
    });

    let newQS = qs.stringify(queryObj);
    if (newQS.length) {
        newQS = `?${ newQS }`;
    }

    return newQS;
}

export function getCssVariable(variable) {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue(variable));
}

// Get navigation height for smooth scrolling
export function getNavOffset() {
    const navs = document.querySelectorAll('.mini-header, .link-rail-wrapper--sticky, .events-controls, .event-detail-header__sticky');
    const navHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--nav-height'));
    const totalNavsHeight = navs.length * navHeight;
    let spacing = 0;
    // If there are no navs, don't add spacing
    if (totalNavsHeight > 0) {
        // Subtract 1px for border and 1px for any minor rounding issues
        spacing = getCssVariable('--spacing-medium') - 2;
    }

    return totalNavsHeight + spacing;
}

// Checks if the top of an element is in the viewport
export function topInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        (rect.top >= 0) && (rect.top <= (window.innerHeight || document.documentElement.clientHeight))
    );
}

// Checks if element is at least partially (vertically) within the viewport
export function overlapsViewport(el) {
    const rect = el.getBoundingClientRect();
    // Check if either the top of the element is within the viewport OR
    // the top of the element is further up the page but the element is still within view
    return (
        topInViewport(el) || ((rect.top <= 0) && (rect.top + rect.height > 0))
    );
}

// Scroll to the top of an element if it's top is not in the viewport
export function scrollToElementIfNecessary(el, preferredBehavior = 'auto') {
    if (el && !topInViewport(el)) {
        const behavior = getReducedMotion() ? 'instant' : preferredBehavior;
        window.scrollTo({ top: el.getBoundingClientRect().top + window.scrollY - getNavOffset(), behavior });
    }
}

// Used to disable scrolling on mobile
export function disableScroll() {
    document.body.style.touchAction = 'none';
}

// Used to re-enable scrolling on mobile
export function enableScroll() {
    document.body.style.touchAction = null;
}

// Timeout that returns a promise
export function timeout(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

// Retries fetch n number of times
// Source: https://dev.to/ycmjason/javascript-fetch-retry-upon-failure-2kj3
export const fetchRetry = async (url, options, n = 1) => {
    try {
        return await fetch(url, options);
    } catch (err) {
        if (n === 1) throw err;

        await timeout(1000);
        return await fetchRetry(url, options, n - 1);
    }
};

// Generate a random uuid
export function getUUID() {
    const possible = 'abcdefghijklmnopqrstuvwxyz';
    let text = '';
    for (let i = 0; i < 8; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

// Get query parameters
export function queryParameters() {
    return Object.fromEntries(new URLSearchParams(window.location.search).entries());
}

// Return current y scroll position
export function scrollPosition() {
    return window.scrollY || document.documentElement.scrollTop;
}

// Helper for setting SR focus on appropriate elements
export function setFocus(elementSelector) {
    let focusEl;
    if (elementSelector) {
        focusEl = document.querySelector(elementSelector);
    } else {
        focusEl = document.querySelector('h1');
    }
    if (focusEl) {
        focusEl.focus();
    }
}

// Helper for trapping tabbing within a set of elements
export function trapTabs(container) {
    // Get everything that is tabbable, visible, isn't disabled, and only the checked radio buttons since the rest are accessed by arrow keys
    const tabEls = Array.from(
        container.querySelectorAll('*[tabindex], a, button:not([disabled]), input:not([disabled]):not([type="radio"]), input[type="radio"]:checked:not([disabled]), label, select:not([disabled])')
    ).filter((el) => el.checkVisibility());

    if (tabEls[0]) {
        // Set focus to first tab
        tabEls[0].focus();
        // Handle trapping focus from tabbing backwards from first element
        // Note: if the above tabEls selector isn't accurate, this is going to cause wrapping issues outside the container
        if (tabEls[tabEls.length - 1]) {
            tabEls[0].addEventListener('keydown', (e) => {
                // Check if this is the tab + shift key
                if (e.key === 'Tab' && e.shiftKey) {
                    e.preventDefault();
                    tabEls[tabEls.length - 1].focus();
                }
            });

            // Handle trapping focus from tabbing forwards from last element
            tabEls[tabEls.length - 1].addEventListener('keydown', (e) => {
                // Check if this is the tab key and NOT also shift
                if (e.key === 'Tab' && !e.shiftKey) {
                    e.preventDefault();
                    tabEls[0].focus();
                }
            });
        }
    }
}

// Check if dates are on the same day
export function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear()
        && d1.getMonth() === d2.getMonth()
        && d1.getDate() === d2.getDate();
}

function collectionPutRequest(url) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(url, {
        credentials: 'same-origin',
        method: 'PUT',
        headers: { 'X-CSRF-Token': token },
    }).then(function (response) {
        console.log(response);
    });
}

// Collection records sync helper for admin
export function collectionSync() {
    collectionPutRequest('/admin/sync/collection');
}

// Collection record sync helper for admin
export function collectionRecordSync(url) {
    collectionPutRequest(url);
}

// Remove all classes on an element that match the supplied prefix
export function removeClassByPrefix(el, prefix) {
    const classes = el.className.split(' ').filter((c) => !c.startsWith(prefix));
    el.className = classes.join(' ').trim();
}

// Modified from https://stackoverflow.com/a/30810322/2915749
export function copyText(text) {
    navigator.clipboard.writeText(text).then(() => {
        console.log('Async: Copying to clipboard was successful!');
    }, (err) => {
        console.error('Async: Could not copy text: ', err);
    });
}

// Helper for setting appropriate classes on .sticky elements
function setStickyClasses(el) {
    function onScroll() {
        // Only run if we're not using css sticky
        if (!window.isSmall && !el.classList.contains('sticky--active')) {
            const elHeight = el.offsetHeight;
            const parentHeight = el.parentElement.offsetHeight;
            const parentY = el.parentElement.getBoundingClientRect().y;
            const navOffset = getNavOffset();
            const parallaxFactor = 1 - (elHeight / parentHeight);
            // Total amount that the el can scroll: container - element
            const totalPossible = parentHeight - elHeight;

            // Only run if there's actually room to parallax (container must be larger than el)
            if (totalPossible > 0) {
                const startY = el.getBoundingClientRect().y - navOffset;
                // This ends sort of off by spacing-large, since it's the bottom of the button hitting 45px from the nav (visually)
                const endY = parentY + parentHeight - navOffset;
                // If we're in the parallax zone...
                if (startY <= 0 && endY >= 0) {
                    // Set parallax y based on scroll distance of the parent element
                    const parentScroll = Math.abs(parentY - navOffset);
                    // - ((parentScroll * 45) / parentHeight) if we want to end 45px earlier
                    const y = parentScroll * parallaxFactor;
                    el.style.transition = '';
                    el.style.transform = `translateY(${ y }px)`;
                } else if (startY >= 0) {
                    // If we haven't gotten to the parallax zone yet, make sure el is aligned to the top
                    // ...and add slight transition so it's not choppy if we're skipping frames
                    Object.assign(el.style, {
                        transform: 'translateY(0px)',
                        transition: '100ms transform linear',
                    });
                } else {
                    // If we've past the parallax zone, make sure el is aligned to bottom
                    // ...and add slight transition so it's not choppy if we're skipping frames
                    Object.assign(el.style, {
                        transform: `translateY(${ totalPossible }px)`,
                        transition: '100ms transform linear',
                    });
                }
            }
        }
    }

    // Make sure element height + nav height is less than the window height (with a bit of tolerance for being close)
    const rect = el.getBoundingClientRect();
    if ((window.innerHeight / (rect.height + getNavOffset())) > 1) {
        // Sticky
        el.classList.add('sticky--active');
        Object.assign(el.style, {
            top: `${ getNavOffset() }px`,
            transform: '',
            transition: '',
        });
    } else {
        // Don't sticky (reset everything)
        el.classList.remove('sticky--active');
        Object.assign(el.style, {
            top: '',
            transform: '',
            transition: '',
        });

        // Setup parallax for if we don't have room to sticky the element
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...

            document.addEventListener('scroll', onScroll);
        } else {
            // Trigger a scroll since parallax behavior is based on scrolling, and this will handle oddness on window resize
            document.dispatchEvent(new Event('scroll'));
        }
    }
}

// Set/unset any elements with .sticky that should only stick if they fit in the viewport
export function adjustSticky() {
    [...document.querySelectorAll('.sticky')].forEach((stickyEl) => {
        // If there's images or videos we need to wait for them to load
        if (stickyEl.querySelector('img')) {
            imagesLoaded(stickyEl, () => {
                setStickyClasses(stickyEl);
            });
        } else if (stickyEl.querySelector('video')) {
            const video = stickyEl.querySelector('video');
            video.onloadeddata = () => {
                setStickyClasses(stickyEl);
            };
        } else {
            setStickyClasses(stickyEl);
        }
    });
}

// Vanilla slide down animation (depends on css transition being set on element)
export function slideDown(el) {
    // Reduced motion should be instant
    if (getReducedMotion()) {
        el.style.display = 'block';
        el.style.height = 'auto';
        el.classList.add('active');
    } else {
        // Quickly get the height then set back to 0
        el.style.display = 'block';
        el.style.height = 'auto';
        const { height } = el.getBoundingClientRect();
        el.style.height = '0px';

        // Goofy but needed for css animation to properly fire
        setTimeout(() => {
            el.classList.add('active');
            el.style.height = `${ height }px`;
        }, 0);

        // Remove fixed height to account for viewport/wrapping changes
        setTimeout(() => {
            el.style.height = 'auto';
        }, window.transitionDuration);
    }
}

// Vanilla slide up animation (depends on css transition being set on element)
export function slideUp(el) {
    // Reduced motion should be instant
    if (getReducedMotion()) {
        el.style.display = null;
        el.classList.remove('active');
    } else {
        // Set the height explicitly so it will animate properly to zero (it may currently be set to "auto")
        const { height } = el.getBoundingClientRect();
        el.style.height = `${ height }px`;

        // Goofy but needed for css animation to properly fire
        setTimeout(() => {
            el.classList.remove('active');
            el.style.height = '0px';
        }, 0);

        // Wait for animation to complete before fully hiding
        setTimeout(() => {
            el.style.display = null;
        }, window.transitionDuration);
    }
}

// Used in both admin and frontend
export function setupViewCounts() {
    // Asynchronously grab Ahoy views, since these can be costly
    document.querySelectorAll('.ahoy-views').forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true'; // Only initialize once...
            // Pull url from element if supplied, otherwise fallback to the requesting page url
            let { url, text } = el.dataset;
            if (url === undefined) {
                url = `/api/analytics/views/url?url=${ encodeURIComponent(window.location.pathname) }`;
            }

            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (text === undefined) {
                        text = `${ data['30_days'] === 1 ? 'view' : 'views' } in the last 30 days`;
                    }

                    const increasing = data['30_days'] > data['prev_30_days'];
                    const infinity = '\u221E';
                    let changeString;
                    if (data['prev_30_days'] === 0) {
                        // Return infinity symbol if growth from zero, else 0
                        changeString = data['30_days'] > 0 ? infinity : '0'
                    } else {
                        changeString = Math.abs((((data['30_days'] - data['prev_30_days']) / data['prev_30_days']) * 100)).toLocaleString(undefined, { maximumFractionDigits: 0 })
                    }

                    // Only add percent symbol if not infinite
                    if (changeString !== infinity) {
                        changeString += '%';
                    }

                    el.innerHTML = `<span class="ahoy-views__number${ increasing ? ' ahoy-views__number--increasing' : '' }">${ data['30_days'].toLocaleString() }<span class="ahoy-views__delta" data-tooltip-content="Change in views from previous 30 days">(<i class="fa-solid fa-arrow-${ increasing ? 'up' : 'down' }"></i> ${ changeString })</span></span><span class="ahoy-views__text">${ text }</span>`;
                    setupTooltips();
                });
        }
    });
}

// Safely mount and unmount React components on form submit or page navigate
// Rendering/navigating in Turbo does not trigger React unmounts automatically
export function mountReactComponent(rootElement, Component) {
    // Check if component has already been mounted
    if (rootElement._reactRoot) return;

    // Create a new root and save it on the container
    rootElement._reactRoot = createRoot(rootElement);

    // Render the app using the newly created root
    rootElement._reactRoot.render(Component);

    // React components do not automatically unmount on Turbo page load
    const unmountThisComponent = () => {
        if (document.body.contains(rootElement) || !rootElement._reactRoot) return;

        rootElement._reactRoot.unmount();
        rootElement._reactRoot = null;

        document.removeEventListener('turbo:submit-end', unmountThisComponent);
        document.removeEventListener('turbo:render', unmountThisComponent);
    };

    document.addEventListener('turbo:submit-end', unmountThisComponent);
    document.addEventListener('turbo:render', unmountThisComponent);
}

export function defineBreakpoints() {
    window.isNavSmall = window.innerWidth < 800;

    // these breakpoint values are the same as those defined in base.scss
    window.isSmall = window.innerWidth < 650;
    window.isMedium = window.innerWidth > 650 && window.innerWidth < 1000;
}
