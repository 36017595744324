//=================================================
// Jumplinks
//=================================================
import { getNavOffset, isAdmin } from './utilities';
import { getReducedMotion } from './user-preferences';

function scrollToAnchor(el) {
    const anchorOffset = (el.getBoundingClientRect().top + window.scrollY) - getNavOffset();
    const behavior = getReducedMotion() ? 'instant' : 'smooth';
    window.scrollTo({ top: anchorOffset, behavior: behavior });
    // Changing history state in admin is a little wonky with Bootstrap modals (things can shift underneath when the modal opens)
    if (!isAdmin()) {
        window.history.replaceState(window.history.state, document.title, `${ window.location.pathname }${ window.location.search }#${ el.id }`);
    }
}

function getLocalLinks() {
    const localLinks = [];
    const currentURL = new URL(window.location.href);

    // Don't include links in primary navigation
    document.querySelectorAll('main a:not(.footnote-indicator), .admin-jumplinks a').forEach(el => {
        const url = new URL(el.href, currentURL); // Resolve relative URLs
        const href = el.getAttribute('href');
        if (href) {
            const match = document.getElementById(href.slice(1));
            // Check if the link is an anchor or a link to the current page and it matches something
            if (match && url.hash && (url.pathname === currentURL.pathname || url.href === el.href)) {
                localLinks.push(el);
            }
        }
    });

    return localLinks;
}

export default function setupJumplinks() {
    // Setup click handling for all local links
    const localLinks = getLocalLinks();
    localLinks.forEach((el) => {
        if (el.dataset.initialized !== 'true') {
            el.dataset.initialized = 'true';

            el.addEventListener('click', (e) => {
                e.preventDefault();
                const match = document.getElementById(el.getAttribute('href').slice(1));
                if (match) {
                    scrollToAnchor(match);
                }
            });
        }
    });

    // Jumplinks rails have an active state based on scroll depth
    document.querySelectorAll('.jumplinks').forEach((wrapper) => {
        // Clean up existing observer if it exists
        if (wrapper.jumplinksObserver) {
            wrapper.jumplinksObserver.disconnect();
        }
        const links = [];
        const targets = [];

        wrapper.querySelectorAll('a').forEach((el) => {
            const match = document.getElementById(el.getAttribute('href').slice(1));
            if (match) {
                links.push(el);
                targets.push(match.offsetHeight > 0 ? match : match.parentElement);
            }
        });

        // Base what's active by the most visible element within the top 40% of the window
        if (targets.length > 0) {
            const observer = new IntersectionObserver((entries) => {
                let bestMatch = null;
                let maxIntersection = 0;
                const viewportHeight = window.innerHeight;
                const bottomBoundary = viewportHeight * 0.4; // 40% of viewport height

                entries.forEach(entry => {
                    const rect = entry.target.getBoundingClientRect();

                    // Only consider targets in the top 40% of the viewport
                    if (rect.top < bottomBoundary && rect.bottom > 0) {
                        const visibleHeight = Math.min(rect.bottom, bottomBoundary) - Math.max(rect.top, 0);
                        const intersectionRatio = visibleHeight / entry.target.offsetHeight;

                        if (intersectionRatio > maxIntersection) {
                            maxIntersection = intersectionRatio;
                            bestMatch = entry.target;
                        }
                    }
                });

                if (bestMatch) {
                    const index = targets.indexOf(bestMatch);
                    links.forEach(link => link.classList.remove('active'));
                    links[index].classList.add('active');

                    // Optional background color for testing
                    // targets.forEach(t => t.style.backgroundColor = '');
                    // bestMatch.style.backgroundColor = 'rgba(255,0,0,0.2)';
                }
            }, {
                threshold: 0,
                rootMargin: '-40% 0px -60% 0px'
            });

            targets.forEach(target => observer.observe(target));
            wrapper.jumplinksObserver = observer; // Store observer for cleanup
        }
    });

    // Adjust scroll once window has loaded since things may have moved
    if (window.location.hash) {
        const el = document.querySelector(window.location.hash);
        if (el) {
            const anchorOffset = (el.getBoundingClientRect().top + window.scrollY) - getNavOffset();
            window.scrollTo({ top: anchorOffset });
        }
    }
}
