const RATE_LIMITS = {
    search: 500,
    autocomplete: 250,
};

const MIN_LENGTHS = { autocomplete: 3, }

// Given an object of query params replace the window history/url
export function replaceHistory(queryArray) {
    const params = new URLSearchParams(window.location.search);
    // Clear all existing parameters
    Array.from(params.keys()).forEach(key => {
        params.delete(key);
    });

    // Then, set new parameters from queryArray, excluding non-truthy values
    queryArray.forEach((entry) => {
        if (entry.value) {
            if (Array.isArray(entry.value)) {
                // Handle array values (e.g., for checkbox groups or multi-selects)
                entry.value.forEach(val => {
                    if (val) {
                        params.append(entry.name, val);
                    }
                });
            } else {
                params.set(entry.name, entry.value);
            }
        }
    });

    let url = window.location.pathname;
    if (params.toString()) {
        url = `${ url }?${ params }`;
    }

    window.history.replaceState(window.history.state, '', url);
}

export function getAllFields(wrapper) {
    const fields = [];
    const radioGroups = {};
    const ignoreFields = ['authenticity_token']

    wrapper.querySelectorAll('input, select, textarea').forEach((el) => {
        // Skip elements without names or disabled elements or ones we want to ignore
        if (!el.name || el.disabled || ignoreFields.includes(el.name)) {
            return;
        }

        let fieldEntry = {
            name: el.name,
            value: undefined,
            default: el.dataset.default,
        };

        // Allow for overrides of field names
        if (el.dataset.field) {
            fieldEntry.name = el.dataset.field;
        }

        switch (el.type) {
            case 'checkbox':
                if (fieldEntry.name.endsWith('[]')) {
                    // This is an array type checkbox, gather all checked values into an array
                    fieldEntry.name = fieldEntry.name.slice(0, -2); // Remove the last two characters '[]'
                    if (!radioGroups[fieldEntry.name]) {
                        radioGroups[fieldEntry.name] = { ...fieldEntry, value: [] }; // Initialize as an empty array if not already present
                    }
                    if (el.checked) {
                        radioGroups[fieldEntry.name].value.push(el.value);
                    }
                } else {
                    // Normal checkbox handling, needs to be passed as 1 or 0 for Ransack
                    fieldEntry.value = el.checked ? 1 : 0;
                    fields.push(fieldEntry);
                }
                break;
            case 'radio':
                if (el.checked) {
                    if (el.value !== el.dataset.default && el.value !== '') {
                        if (!radioGroups[fieldEntry.name]) {
                            radioGroups[fieldEntry.name] = { ...fieldEntry, value: el.value };
                        } else {
                            radioGroups[fieldEntry.name].value = el.value;
                        }
                    }
                    // If it's the default value, we don't add it to radioGroups at all
                }
                break;
            case 'select-multiple':
                fieldEntry.value = Array.from(el.selectedOptions).map(option => option.value);
                fields.push(fieldEntry);
                break;
            default:
                fieldEntry.value = el.value;
                if (fieldEntry.value !== fieldEntry.default && fieldEntry.value !== '') {
                    fields.push(fieldEntry);
                }
        }
    });

    // Add radio group and checkbox array entries to fields
    Object.values(radioGroups).forEach(entry => fields.push(entry));

    return fields.filter(field => field.value !== undefined && field.value !== '');
}

// Get count of active (and non default) form fields within wrapping element
export function activeFieldCount(wrapper) {
    let count = 0;

    const allFields = getAllFields(wrapper);
    allFields.forEach((entry) => {
        const isTruthy = entry.value && entry.value.toString() !== '';
        const isDefault = entry.value === entry.default;

        if (isTruthy && !isDefault) {
            count += 1;
        }
    });

    return count;
}

// Reset all inputs/selects, allowing for defaults to be restored OR cleared
export function resetAllFields(wrapper) {
    // Clear standard elements
    wrapper.querySelectorAll('input, select, textarea').forEach((el) => {
        // Skip resetting the authenticity_token hidden field
        if (el.type === 'hidden' && el.name === 'authenticity_token') {
            return;
        }

        // Don't modify the hidden input for the Rails form helper's checkbox input
        // https://api.rubyonrails.org/v3.2/classes/ActionView/Helpers/FormHelper.html#method-i-check_box-label-Gotcha
        const { nextSibling } = el;
        const isHiddenInput = el.type === 'hidden' &&
            nextSibling?.tagName?.toLowerCase() === 'input' &&
            nextSibling.getAttribute('type') === 'checkbox' &&
            el.getAttribute('name') === nextSibling?.getAttribute('name');
        if (isHiddenInput) {
            return;
        }

        if (el.tagName.toLowerCase() === 'select') {
            el.selectedIndex = 0;
            // Allow for resetting to defaults that aren't the first option
            if (el.dataset.default) {
                el.value = el.dataset.default;
            }
        } else if (['checkbox', 'radio'].includes(el.type)) {
            // Only check if by default it should be
            el.checked = (el.dataset.default === el.value);
        } else {
            el.value = '';
        }
    });

    // Remove any exhibition filters
    wrapper.querySelectorAll('.collection-exhibition-filter').forEach((el) => {
        el.remove();
    });

    // Reset all clearable buttons
    wrapper.classList.remove('list--resettable');
    wrapper.querySelectorAll('.input__clear').forEach((el) => {
        el.classList.remove('active');
    });
}

// Update any controls based on active form fields (e.g. the "reset" button + filters button count)
export function updateFormControls() {
    const wrapper = document.querySelector('.list');
    if (wrapper) {
        // Filters button
        const overlay = wrapper.querySelector('.overlay--filters');
        const overlayToggle = wrapper.querySelector('.overlay__toggle');
        if (overlay && overlayToggle) {
            const count = activeFieldCount(overlay);
            if (count > 0) {
                overlayToggle.classList.add('active');
                overlayToggle.innerText = `Filters (${ count })`;
            } else {
                overlayToggle.classList.remove('active');
                overlayToggle.innerText = 'Filters';
            }
        }

        // Reset button
        if (activeFieldCount(wrapper) > 0) {
            wrapper.classList.add('list--resettable');
        } else {
            wrapper.classList.remove('list--resettable');
        }
    }
}

// Run search and update history if appropriate
export function runSearch(wrapper) {
    if (activeFieldCount(wrapper) > 0) {
        wrapper.classList.add('list--resettable');
    } else {
        wrapper.classList.remove('list--resettable');
    }

    // Update any "Filters" toggles etc.
    updateFormControls();
    wrapper.querySelector('form').requestSubmit();
}

// Closes any open autocompletes (optional targetEl in case we're clicking within an autocomplete and don't want to close)
export function closeAutocompletes(targetEl = null) {
    const autocompletes = document.querySelectorAll('.autocomplete');
    if (autocompletes) {
        if (targetEl && targetEl.matches('.autocomplete *')) {
            const currentWrapper = targetEl.closest('.autocomplete');
            // Close everything but the clicked one
            autocompletes.forEach((el) => {
                if (el !== currentWrapper) {
                    el.querySelector('.autocomplete__list')?.classList.remove('active');

                    const searchEl = el.querySelector('input[type=search]');
                    if (searchEl) {
                        searchEl.setAttribute('aria-expanded', 'false');
                    }
                }
            });
        } else {
            // Close everything
            autocompletes.forEach(el => {
                el.querySelector('.autocomplete__list')?.classList.remove('active')

                const searchEl = el.querySelector('input[type=search]');
                if (searchEl) {
                    searchEl.setAttribute('aria-expanded', 'false');
                }
            });
        }
    }
}

// Utility function for rate limiting
function createRateLimiter(delay) {
    let timeoutId = null;
    let lastRun = 0;

    return (callback) => {
        const now = Date.now();

        // Clear any pending timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // If enough time has passed, run immediately
        if (now - lastRun >= delay) {
            lastRun = now;
            callback();
        } else {
            // Otherwise schedule to run after delay
            timeoutId = setTimeout(() => {
                lastRun = Date.now();
                callback();
            }, delay);
        }
    };
}

// Create rate limiters
const searchLimiter = createRateLimiter(RATE_LIMITS.search);
const autocompleteLimiter = createRateLimiter(RATE_LIMITS.autocomplete);

// Rate limited version of runSearch
function runSearchWithRateLimit(wrapper) {
    searchLimiter(() => {
        if (activeFieldCount(wrapper) > 0) {
            wrapper.classList.add('list--resettable');
        } else {
            wrapper.classList.remove('list--resettable');
        }

        // Update any "Filters" toggles etc.
        updateFormControls();
        wrapper.querySelector('form').requestSubmit();
    });
}

function handleSearch(e) {
    runSearchWithRateLimit(e.target.closest('.list'));
}

// Modified autocomplete function with proper rate limiting
function runAutocomplete(autocompleteEl) {
    const searchEl = autocompleteEl.querySelector('input[type=search]');
    if (!searchEl) {
        return;
    }

    // Create the autocomplete list if it doesn't already exist
    let listEl = autocompleteEl.querySelector('.autocomplete__list');
    if (!listEl) {
        listEl = document.createElement('div');
        listEl.classList.add('autocomplete__list');
        listEl.setAttribute('role', 'listbox');
        autocompleteEl.appendChild(listEl);
    }

    const query = searchEl.value.trim();
    let removeAutocomplete = false;

    if (query.length >= MIN_LENGTHS.autocomplete) {
        autocompleteLimiter(() => {
            const url = `/api/autocomplete/${ autocompleteEl.dataset.autocomplete }/search?search=${ encodeURIComponent(query) }`;

            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (searchEl.value.trim() !== query) {
                        // If the input value has changed since this request was initiated, ignore the results
                        return;
                    }

                    if (data.length) {
                        const ul = document.createElement('ul');
                        data.forEach((result) => {
                            const li = document.createElement('li');
                            const button = document.createElement('button');
                            button.innerHTML = result.html;
                            button.classList.add('autocomplete__option');
                            button.dataset.text = result.text;
                            button.type = 'button';
                            button.setAttribute('role', 'option');
                            li.appendChild(button);
                            ul.appendChild(li);
                        });

                        // Keep track if a previous option had focus
                        const focusedOption = document.activeElement.classList.contains('autocomplete__option');
                        listEl.replaceChildren(ul);

                        // Make sure there's results they're not exactly the same as what's searched
                        if (!(data.length === 1 && data[0].text.toLowerCase() === query.toLowerCase())) {
                            searchEl.setAttribute('aria-expanded', 'true');
                            listEl.classList.add('active');

                            // If an option previously had focus and we just replaced those elements put back the focus
                            if (focusedOption) {
                                listEl.querySelector('.autocomplete__option')?.focus();
                            }
                        } else {
                            removeAutocomplete = true;
                        }
                    } else {
                        removeAutocomplete = true;
                    }
                });
        });
    } else {
        removeAutocomplete = true;
    }

    if (removeAutocomplete) {
        searchEl.setAttribute('aria-expanded', 'false');
        listEl.classList.remove('active');
        listEl.innerHTML = null;
    }
}

// List updating/searching is handled partly in JS and partly by native Turbo Drive/Frames behavior
export function setupLists() {
    document.querySelectorAll('.list').forEach((wrapper) => {
        if (wrapper.dataset.initialized !== 'true') {
            wrapper.dataset.initialized = 'true'; // Only initialize once...

            const form = wrapper.querySelector('form');
            // Set resettable status in case it wasn't handled in the templates
            if (activeFieldCount(wrapper) > 0) {
                wrapper.classList.add('list--resettable');
            } else {
                wrapper.classList.remove('list--resettable');
            }

            // Listen for changes on input elements
            wrapper.querySelectorAll('input, select').forEach((el) => {
                // Remove and then add to make sure we don't add multiple times
                el.removeEventListener('input', handleSearch);
                el.addEventListener('input', handleSearch);
            });

            // Handle dynamically added elements
            wrapper.addEventListener('click', (e) => {
                const listAll = e.target.closest('.list__all');
                const sortLink = e.target.closest('.sort_link');
                const autocompleteOption = e.target.closest('.autocomplete__option');
                const pagination = e.target.closest('.pagination__btn');

                // Note: "all" and "reset" have the same functionality
                if (listAll) {
                    // Show all
                    e.preventDefault();

                    resetAllFields(wrapper);
                    runSearch(wrapper);

                    if (useAnalytics) {
                        dataLayer.push({
                            event: 'search',
                            label: 'all',
                            value: 'true',
                        });
                    }
                } else if (sortLink) {
                    // Ransack sort links (admin)
                    // Set a hidden input with the sort value
                    const targetParams = new URLSearchParams(e.target.href.split('?')[1]);
                    const input = wrapper.querySelector('[name="q[s]"]');
                    if (input) {
                        input.value = targetParams.get('q[s]');
                    }

                    runSearch(wrapper);
                } else if (autocompleteOption) {
                    // Autocomplete option selection
                    const autocompleteEl = autocompleteOption.closest('.autocomplete');
                    const searchEl = autocompleteEl.querySelector('input[type=search]');
                    searchEl.value = autocompleteOption.dataset.text;

                    closeAutocompletes()
                    runSearch(wrapper);

                    if (useAnalytics) {
                        dataLayer.push({
                            event: 'search',
                            label: 'autocomplete',
                            value: autocompleteOption.dataset.text,
                        });
                    }
                } else if (pagination) {
                    // If we're manually handling history then pagination clicks should push to the URL
                    if (wrapper.dataset.history === 'true') {
                        const url = new URL(window.location);
                        url.searchParams.set('page', pagination.dataset.page);
                        window.history.replaceState(window.history.state, '', url);
                    }
                }
            });

            // Make "clear" button visible or invisible depending on content in the inputs
            wrapper.querySelectorAll('input[type=text], input[type=search]').forEach((el) => {
                el.addEventListener('input', () => {
                    const clearEl = el.parentElement.querySelector('.input__clear');
                    if (el.value === '') {
                        clearEl?.classList.remove('active');
                    } else {
                        clearEl?.classList.add('active');
                    }
                });
            });

            // Handle clicks on randomize
            wrapper.querySelectorAll('.list__randomize').forEach((el) => {
                el.addEventListener('click', () => {
                    const input = wrapper.querySelector('[name="q[s]"]');
                    if (input) {
                        input.value = 'random';
                        input.dispatchEvent(new Event('input'));
                    }

                    if (useAnalytics) {
                        dataLayer.push({
                            event: 'search',
                            label: 'randomize',
                            value: 'true',
                        });
                    }
                });
            });

            // Handle clicks on search input clears (which only clear that input)
            wrapper.querySelectorAll('.input__clear').forEach((el) => {
                el.addEventListener('click', () => {
                    el.classList.remove('active');
                    const input = el.parentElement.querySelector('input:not([type=hidden])');
                    input.value = '';

                    // Clear autocomplete if present
                    const currentAutocompleteEl = el.parentElement.querySelector('.autocomplete__list');
                    if (currentAutocompleteEl) {
                        currentAutocompleteEl.innerHTML = null;
                    }

                    input.focus();
                    input.dispatchEvent(new Event('input'));

                    if (useAnalytics) {
                        dataLayer.push({
                            event: 'search',
                            label: 'clear',
                            value: 'true',
                        });
                    }
                });
            });

            // Autocomplete input listeners
            wrapper.querySelectorAll('.autocomplete').forEach((el) => {
                const searchEl = el.querySelector('input[type=search]');
                // This is added dynamically so we have to re-check for it usually
                let autocompleteEl = el.querySelector('.autocomplete__list');

                // Close autocomplete if focus shifts outside the container
                el.addEventListener('focusout', (e) => {
                    if (!el.contains(e.relatedTarget)) {
                        // NOTE: needs slight delay otherwise on mobile safari the click event runs too late and has the wrong target element since the autocomplete has already closed
                        setTimeout(() => {
                            closeAutocompletes(el);
                        }, 10);
                    }
                });

                // Handle showing options
                if (searchEl) {
                    searchEl.addEventListener('input', () => {
                        runAutocomplete(el);
                    });
                }

                // If the search input is clicked/re-focused, show autocompletes again
                ['click', 'focus'].forEach((e) => {
                    searchEl.addEventListener(e, () => {
                        autocompleteEl = el.querySelector('.autocomplete__list');
                        if (autocompleteEl?.children.length) {
                            autocompleteEl.classList.add('active');

                            searchEl.setAttribute('aria-expanded', 'true');
                        }
                    });
                });

                // Set consistent escape key handling across browsers (some clear type search inputs)
                el.addEventListener('keydown', (e) => {
                    autocompleteEl = el.querySelector('.autocomplete__list');

                    if (e.key === 'Enter' || e.key === 'Escape') {
                        closeAutocompletes()

                        if (e.key === 'Escape') {
                            // Prevents clearing of input
                            e.preventDefault();
                        }

                        return;
                    }

                    // Add special key handling for autocompletes so they behave more like Google
                    if (document.activeElement) {
                        const activeOption = document.activeElement.classList.contains('autocomplete__option');
                        const activeSearch = document.activeElement === searchEl;

                        // If focus is on search and we arrow key down let the user go through the options
                        if (activeSearch) {
                            switch (e.key) {
                                case 'ArrowUp':
                                    e.preventDefault();
                                    break
                                case 'ArrowDown':
                                    e.preventDefault();

                                    if (autocompleteEl && autocompleteEl.classList.contains('active')) {
                                        autocompleteEl.querySelector('.autocomplete__option')?.focus();
                                    }
                                    break
                            }
                        }

                        // If focus is on options let the user arrow through them (in addition to default tabbing)
                        if (activeOption) {
                            switch (e.key) {
                                case 'ArrowDown':
                                case 'ArrowRight':
                                    e.preventDefault();

                                    document.activeElement.parentElement.nextElementSibling?.firstElementChild.focus();
                                    break

                                case 'ArrowUp':
                                case 'ArrowLeft':
                                    e.preventDefault();

                                    const prevSibling = document.activeElement.parentElement.previousElementSibling;
                                    if (prevSibling) {
                                        prevSibling.firstElementChild.focus();
                                    } else {
                                        // If there's no previous option go back to the input
                                        searchEl.focus();
                                        // Move cursor to end of input value, which for some bad reason has a race condition with focus() in this context
                                        setTimeout(() => {
                                            const length = searchEl.value.length;
                                            searchEl.setSelectionRange(length, length);
                                        }, 0)
                                        break
                                    }
                                    break
                            }
                        }
                    }
                });
            });

            // Suggestions (clicking on them should fill the target input)
            wrapper.querySelectorAll('.suggestion').forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    let suggestion = el.innerText;
                    if (el.dataset.value) {
                        suggestion = el.dataset.value;
                    }

                    const target = wrapper.querySelector(el.dataset.target);
                    if (target) {
                        target.value = suggestion;
                        target.dispatchEvent(new Event('input'));
                    }

                    if (useAnalytics) {
                        dataLayer.push({
                            event: 'search',
                            label: 'suggestion',
                            value: suggestion,
                        });
                    }
                });
            });

            if (form) {
                // Only autofocus on large screens since the keyboard takes up so much room
                if (!window.isSmall) {
                    const primaryInput = form.querySelector('input[type=search]');
                    if (primaryInput) {
                        primaryInput.focus();
                        // Move cursor to end of input value
                        const length = primaryInput.value.length;
                        primaryInput.setSelectionRange(length, length);
                    }
                }

                form.addEventListener('keypress', (e) => {
                    // Close any on screen keyboards etc on hitting enter (which implies finishing typing)
                    if (e.key === 'Enter') {
                        document.activeElement.blur();
                    }
                });
            }

            // Any other analytics
            const searchEl = wrapper.querySelector('input[type=search]');
            if (searchEl) {
                // Track searches on blur since that should mean someone is done typing
                searchEl.addEventListener('blur', () => {
                    if (searchEl.value !== '') {
                        dataLayer.push({
                            event: 'search',
                            label: 'search',
                            value: searchEl.value,
                        });
                    }
                });
            }
        }
    });
}
