import { arrow, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { getUUID } from './utilities';

// Track all tooltips for global management
const tooltips = [];

export function setupTooltips() {
    const showDelay = window.transitionDurationFast;
    const hideDelay = window.transitionDuration;
    const transitionDuration = 200;

    document.querySelectorAll('[data-tooltip-content]').forEach((el) => {
        if (el._hasTooltip) return;

        // Create tooltip container
        const tooltip = document.createElement('div');
        const id = getUUID();
        tooltip.id = id;
        tooltip.className = 'tooltip-container';
        tooltip.style.display = 'none';
        tooltip.innerHTML = el.dataset.tooltipContent;
        tooltip.setAttribute('role', 'tooltip');
        el.setAttribute('aria-describedby', id);

        // Create arrow element
        const arrowEl = document.createElement('div');
        arrowEl.className = 'tooltip-arrow';
        tooltip.appendChild(arrowEl);

        // Add tooltip to the proper parent (dialog if inside dialog)
        const dialog = el.closest('dialog');
        if (dialog) {
            dialog.appendChild(tooltip);
        } else {
            document.body.appendChild(tooltip);
        }

        // Parse tooltip options (placement + offset)
        const placement = el.dataset.tooltipPlacement || 'top';
        let offsetDistance = 8;
        if (el.dataset.tooltipOffset) {
            try {
                const parsedOffset = JSON.parse(el.dataset.tooltipOffset);
                offsetDistance = parsedOffset[1];
            } catch (error) {
                console.error('Error parsing JSON:', error.message);
            }
        }

        // Store timers and state directly on the element
        el._tooltipState = {
            cleanup: null,
            showTimer: null,
            hideTimer: null,
            isActive: false
        };

        const showTooltip = () => {
            tooltip.style.display = 'block';
            el.setAttribute('aria-expanded', 'true');

            // Zero length timeout to ensure transition works
            setTimeout(() => {
                tooltip.style.opacity = '1';
            }, 0);
        };

        const hideTooltip = () => {
            tooltip.style.opacity = '0';
            el.setAttribute('aria-expanded', 'false');

            // After the transition completes, make it hidden
            setTimeout(() => {
                // Only hide if opacity is still 0 (another show hasn't started)
                if (tooltip.style.opacity === '0') {
                    tooltip.style.display = 'none';
                }

                // Clean up position tracking
                if (el._tooltipState.cleanup) {
                    el._tooltipState.cleanup();
                    el._tooltipState.cleanup = null;
                }
            }, transitionDuration);
        };

        const activateTooltip = () => {
            clearTimeout(el._tooltipState.hideTimer);
            el._tooltipState.isActive = true;
            el._tooltipState.hideTimer = null;

            clearTimeout(el._tooltipState.showTimer);
            el._tooltipState.showTimer = setTimeout(() => {
                el._tooltipState.cleanup = autoUpdate(el, tooltip, () => {
                    computePosition(el, tooltip, {
                        placement: placement,
                        middleware: [
                            offset(offsetDistance),
                            flip(),
                            shift({ padding: 5 }),
                            arrow({ element: arrowEl })
                        ]
                    }).then(({ x, y, placement, middlewareData }) => {
                        // Position tooltip
                        Object.assign(tooltip.style, {
                            left: `${ x }px`,
                            top: `${ y }px`
                        });

                        if (middlewareData.arrow) {
                            const { x: arrowX, y: arrowY } = middlewareData.arrow;

                            // Determine static side based on placement
                            const staticSide = {
                                top: 'bottom',
                                right: 'left',
                                bottom: 'top',
                                left: 'right',
                            }[placement.split('-')[0]];

                            // Get the arrow offset from CSS variable
                            const spacingTiny = getComputedStyle(document.documentElement).getPropertyValue('--spacing-tiny').trim();
                            const arrowOffset = parseFloat(spacingTiny) * 0.5 * -1 + 'px';

                            // Set arrow position
                            Object.assign(arrowEl.style, {
                                left: arrowX != null ? `${ arrowX }px` : '',
                                top: arrowY != null ? `${ arrowY }px` : '',
                                [staticSide]: arrowOffset,
                            });
                        }
                    });
                });

                // Show the tooltip
                showTooltip();
            }, showDelay);
        };

        const deactivateTooltip = () => {
            clearTimeout(el._tooltipState.showTimer);
            el._tooltipState.isActive = false;
            el._tooltipState.showTimer = null;

            el._tooltipState.hideTimer = setTimeout(() => {
                // Hide if tooltip is still inactive after a slight delay
                if (!el._tooltipState.isActive) {
                    hideTooltip();
                }
            }, hideDelay);
        };

        const cleanupTooltip = () => {
            tooltip.style.opacity = '0';
            tooltip.style.display = 'none';

            // Clear timers
            clearTimeout(el._tooltipState.showTimer);
            clearTimeout(el._tooltipState.hideTimer);

            // Remove position tracking
            if (el._tooltipState.cleanup) {
                el._tooltipState.cleanup();
            }

            // Remove event listeners
            el.removeEventListener('mouseenter', activateTooltip);
            el.removeEventListener('mouseleave', deactivateTooltip);
            el.removeEventListener('focus', activateTooltip);
            el.removeEventListener('blur', deactivateTooltip);
            tooltip.removeEventListener('mouseenter', activateTooltip);
            tooltip.removeEventListener('mouseleave', deactivateTooltip);

            // Remove aria attributes
            el.removeAttribute('aria-expanded');
            el.removeAttribute('aria-describedby');

            // Reset state
            el._hasTooltip = false;
            el._tooltipState = null;
        };

        // Store the cleanup function
        el._tooltipCleanup = cleanupTooltip;

        // Keep track of tooltip
        tooltips.push({
            element: el,
            tooltip,
            cleanup: cleanupTooltip
        });

        // Trigger element listeners
        el.addEventListener('mouseenter', activateTooltip);
        el.addEventListener('mouseleave', deactivateTooltip);
        el.addEventListener('focus', activateTooltip);
        el.addEventListener('blur', deactivateTooltip);
        // Tooltip listeners
        tooltip.addEventListener('mouseenter', activateTooltip);
        tooltip.addEventListener('mouseleave', deactivateTooltip);

        el._hasTooltip = true;
    });
}

// Function to hide all tooltips (immediately)
export function hideAllTooltips() {
    document.querySelectorAll('.tooltip-container').forEach((tooltip) => {
        tooltip.style.opacity = '0';
        tooltip.style.display = 'none';

        // Find the corresponding element
        const id = tooltip.id;
        const el = document.querySelector(`[aria-describedby="${ id }"]`);

        if (el && el._tooltipState) {
            // Clear timers
            clearTimeout(el._tooltipState.showTimer);
            clearTimeout(el._tooltipState.hideTimer);

            // Clear positioning updates
            if (el._tooltipState.cleanup) {
                el._tooltipState.cleanup();
                el._tooltipState.cleanup = null;
            }

            // Update state
            el._tooltipState.isActive = false;
            el.setAttribute('aria-expanded', 'false');
        }
    });
}
